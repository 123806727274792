import { useState } from 'react';
import { ProjectStatus, PromptStatus } from '@remento/types/project';

import { RMPageBanner } from '@/components/RMPage/RMPageBanner';
import { usePersonQuery } from '@/services/api/person';

import { useProjectQuery, useUnrecordedProjectPrompts } from '../../../services/api/project';

import { ResumeProjectConfirmationModalContainer } from './ResumeProjectConfirmationModal.container';

export interface ProjectBannerContainerProps {
  projectId: string;
}

export function ProjectBannerContainer({ projectId }: ProjectBannerContainerProps) {
  const projectQuery = useProjectQuery(projectId);
  const personQuery = usePersonQuery(projectQuery.data?.subjectPersonIds?.[0]);
  const prompts = useUnrecordedProjectPrompts(projectId, PromptStatus.PENDING);
  const project = projectQuery.data;
  const person = personQuery.data;

  const [resumeModalOpen, setResumeModalOpen] = useState(false);

  if (!project || !person) {
    return null;
  }

  if (project.status === ProjectStatus.PAUSED) {
    return (
      <>
        <RMPageBanner
          message={`${personQuery.data.name?.first} is not currently receiving prompts.`}
          action="Resume prompts"
          onAction={() => setResumeModalOpen(true)}
        />
        <ResumeProjectConfirmationModalContainer
          projectId={projectId}
          open={resumeModalOpen}
          onClose={() => setResumeModalOpen(false)}
        />
      </>
    );
  }

  if (prompts?.length === 0 && project.configuration.timePeriod !== 'PRESENT') {
    return <RMPageBanner message={`${personQuery.data.name?.first} needs a new prompt for their next session.`} />;
  }

  return null;
}
